import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"

// Components
import SEO from "../components/SEO"
import HeadlineIcon from "../modules/HeroMedia/variants/HeadlineIcon"
import TextBlock from "../modules/TextBlock/TextBlock"

// Helpers & Content
import { modernSlaveryContent } from "../utils/content/modernSlaveryContent"
import { fireEvent } from "../utils/helpers"
import ceoSignature from "../assets/images/ceoSignature.png"

// markup
const ModernSlaveryPage = () => {
  const { formatMessage } = useIntl()
  const { seo, mainHero } = modernSlaveryContent

  useEffect(() => {
    fireEvent("nav-scheme", "dark")
  }, [])

  return (
    <div>
      <SEO title={formatMessage({ id: seo.metaTitle })} description={formatMessage({ id: seo.metaDescription })} themeColor="#E3EFFE" />
      <HeadlineIcon content={mainHero} />
      <TextBlock id="page-content">
        <p>
          TIER Operations Limited is a company incorporated in England and Wales and is a wholly-owned subsidiary of TIER Mobility GmbH, a company
          incorporated in Germany and registered with the commercial register of the local court of Charlottenburg under number HRB 198977. TIER
          Mobility GmbH and its subsidiaries (together, the “Group”) has over 900 employees and operates in over 10 countries. The Group has a global
          annual turnover exceeding €50 million.
        </p>
        <p>
          TIER Operations Limited commenced operations in the United Kingdom in September 2020 and, together with the other members of the Group, is
          committed to improving its practices and ensuring that acts of modern slavery and human trafficking are excluded from its business and from
          within its supply chains. We acknowledge our responsibility under the Modern Slavery Act 2015 and aim to ensure transparency within our
          organisation and with our suppliers of goods and services.
        </p>
        <p>
          We are committed to ensuring that there is no modern slavery or human trafficking in our supply chains or in any part of our business. Our
          Supplier Code of Conduct reflects our commitment to acting ethically and with integrity in all our business relationships and to
          implementing and enforcing effective systems and controls to ensure slavery and human trafficking is not taking place anywhere in our supply
          chains.
        </p>
        <h4>Our Business</h4>
        <p>
          TIER is the leading European micro-mobility operator. Its headquarters are in Berlin, Germany, and it operates through branches and
          subsidiaries throughout Europe and in the United Arab Emirates. Its manufacturers include companies based in China and TIER’s
          representatives regularly visit the manufacturers’ premises. The supply contracts entered into from 2020 onwards include obligations for the
          suppliers to undertake compliance with TIER’s Supplier Code of Conduct.
        </p>
        <h4>Social Responsability At TIER</h4>
        <p>
          TIER Operations Limited will not work with any suppliers who have demonstrably and repeatedly failed to comply with basic International
          Labour Organization (ILO) standards.
        </p>
        <p>
          Combining the pursuit of economic objectives with consideration for social and environmental factors is a high priority for TIER. In
          addition to our Supplier Code of Conduct, we have adopted an Employee Code of Conduct and a Diversity, Equality and Inclusion Policy and
          carry out staff training in respect of these policies. We shall appoint a Group Compliance Officer in early 2021 whose role will be to:
        </p>
        <p>Implement and monitor a Group-wide compliance program</p>
        <p>
          Provide training in relation to the risks of modern slavery and human trafficking in our supply chains and our business and implement and
          monitor a comprehensive due diligence process in relation to the Group’s suppliers in order to identify and assess potential risk areas in
          our supply chains.
        </p>
        <p>
          This statement is made pursuant to section 54(1) of the Modern Slavery Act 2015 and constitutes TIER Operations Limited’s slavery and human
          trafficking statement. The directors of TIER Operations Limited shall take the responsibility for implementing this policy statement and its
          objectives and shall provide adequate resources and investment to ensure that slavery and human trafficking is not taking place within the
          organisation within its supply chains.
        </p>
        <p>This statement was approved by the directors of TIER Operations Limited in December 2020.</p>
        <br />
        <br />
        <img src={ceoSignature} className="w-56 -mb-8" />
        <p>
          <strong>Fred Jones</strong> <br /> Director TIER Operations Limited
        </p>
      </TextBlock>
    </div>
  )
}

export default ModernSlaveryPage
