import icon from "../../assets/images/modernSlavery/illuBig.svg"

export const modernSlaveryContent = {
  seo: {
    metaTitle: "metaTitleModerSlavery",
    metaDescription: "metaDescriptionModernSlavery",
  },
  mainHero: {
    headline: "Modern Slavery Act Transparency Statement",
    type: "headline-icon",
    icon: icon,
    alt: "tier-modern-slavery-icon",
    buttonText: "Read More",
    backgroundColor: "bg-blue-background",
    anchor: "#page-content",
  },
}
